import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Input, Label, Row, Col, CardSubtitle } from 'reactstrap';
import { VectorMap as JVectorMap } from 'react-jvectormap';


const reportTypes = [
  { type: 'UXOs', color: '#FF2C2C', label: 'UXOs' },
  { type: 'Military Activity', color: '#04c4bc', label: 'Military Activity' },
  { type: 'Wildlife', color: '#a2845e', label: 'Wildlife Hazard' },
  { type: 'War Crime', color: '#5454d4', label: 'War Crimes' },
  { type: 'Criminal Activity', color: '#fc9404', label: 'Criminal Activity' },
  { type: 'Resource Scarcity', color: '#40c878', label: 'Resource Scarcity' },
  { type: 'Structure Hazard', color: '#ffcc00', label: 'Structure Hazard' },
  { type: 'Natural Disaster', color: '#047cfc', label: 'Natural Disaster' },
  { type: 'Terrorist Activity', color: '#000000', label: 'Terrorist Activity' },
  { type: 'NASA', color: '#32ace5', label: 'NASA Reports' },
];

const ExploreMap = ({ reports = [], parentSelectedTypes, onCheckboxChange, resetMap }) => {
  const predefinedReportTypes = reportTypes.map((rt) => rt.type); 

  const [selectedReportTypes, setSelectedReportTypes] = useState(predefinedReportTypes);

  useEffect(() => {
    if (resetMap) {
      setSelectedReportTypes(predefinedReportTypes);
      onCheckboxChange(predefinedReportTypes); 
    }
  }, [resetMap]);

  useEffect(() => {
    if (parentSelectedTypes) {
      setSelectedReportTypes(parentSelectedTypes);
    }
  }, [parentSelectedTypes]);

  const handleCheckboxChange = (type) => {
    let updatedTypes;
    if (selectedReportTypes.includes(type)) {
      updatedTypes = selectedReportTypes.filter((t) => t !== type);
    } else {
      updatedTypes = [...selectedReportTypes, type];
    }
    setSelectedReportTypes(updatedTypes);
    onCheckboxChange(updatedTypes); 
  };

  const handleSelectAll = () => {
    if (selectedReportTypes.length === reportTypes.length) {
      setSelectedReportTypes([]);
      onCheckboxChange([]);
    } else {
      setSelectedReportTypes(predefinedReportTypes);
      onCheckboxChange(predefinedReportTypes); 
    }
  };

  const isSelectAllChecked = selectedReportTypes.length === reportTypes.length;

  const markers = reports
    .filter((report) => selectedReportTypes.includes(report.reportType))
    .map((report, index) => ({
      latLng: [report.latitude, report.longitude],
      name: `Report: ${report.reportType}`,
      style: { fill: reportTypes.find((rt) => rt.type === report.reportType)?.color || '#000' },
      id: report.documentId,
    }));

  const reportTypeItemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const circleStyle = (color) => ({
    backgroundColor: color,
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    marginRight: '10px',
  });

  const checkboxWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const handleMarkerClick = (e, index) => {
    const markerId = markers[index].id;
    localStorage.setItem('referrer', window.location.pathname);
    window.open(`/admin/report/view/${markerId}`, '_blank');
  };

  return (
    <Card className="map" style={{ overflow: 'visible' }}>
      <CardBody>
        <Row>
          <Col md="4" style={{ overflowY: 'auto', maxHeight: '500px', marginBottom: '50px' }}>
            <CardTitle tag="h5">Explore the Map</CardTitle>
            <CardSubtitle style={{ marginBottom: '20px', display: 'block' }}>Topic Filter</CardSubtitle>
            <div style={reportTypeItemStyle}>
              <Label style={{ marginLeft: '33px', color: 'black' }}>Select All</Label>
              <div style={checkboxWrapperStyle}>
                <Input type="checkbox" onChange={handleSelectAll} checked={isSelectAllChecked} />
              </div>
            </div>
            {reportTypes.map((reportType) => {
              const hasReports = reports.some((report) => report.reportType === reportType.type);

              return (
                <div key={reportType.type} style={reportTypeItemStyle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="report-type-color" style={circleStyle(reportType.color)}></div>
                    <Label style={{ color: hasReports ? 'black' : 'gray' }}>{reportType.label}</Label>
                  </div>
                  <div style={checkboxWrapperStyle}>
                    <Input
                      type="checkbox"
                      id={`checkbox-${reportType.type}`}
                      onChange={() => handleCheckboxChange(reportType.type)}
                      checked={selectedReportTypes.includes(reportType.type)}
                      style={{ cursor: hasReports ? 'pointer' : 'not-allowed', opacity: hasReports ? 1 : 0.5 }}
                    />
                  </div>
                </div>
              );
            })}
          </Col>
          <Col md="8" style={{ overflow: 'hidden' }}>
            <div style={{ height: '500px', width: '100%' }}>
              <JVectorMap
                map="world_mill"
                backgroundColor="#E0E0E0"
                containerStyle={{
                  width: '100%',
                  height: '90%',
                }}
                markers={markers}
                markerStyle={{
                  initial: {
                    fill: '#FF0000',
                    stroke: '#000',
                    "stroke-width": 0.3,
                    r: 6,
                  },
                  hover: {
                    stroke: '#000',
                    "stroke-width": 2,
                    cursor: 'pointer',
                  },
                }}
                onMarkerClick={handleMarkerClick}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
      <p style={{ fontSize: '10px', marginLeft: '20px' }}>
        If the checkboxes are grayed out, there are no reports.
      </p>
    </Card>
  );
};

export default ExploreMap;
