import React, { useState, useEffect, useCallback } from 'react';
import { hazardTypeMapping, bombTypeMapping } from 'utils/reportTypeMapping';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Label, Spinner } from 'reactstrap';
import ExploreMap from '../maps/ExploreMap';
import ReportsTable from '../tables/ReportsTable';
import DownloadButtonBasicReport from '../components/DownloadButtonBasicReport'; 
import { collection, getDocs, query } from 'firebase/firestore';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { firestore } from '../../firebase';
import CustomizableSelect from 'views/components/CustomizableSelect';
import DateRangePickerComponent from 'views/components/DateRangePickerComponent';
import { geographicRegions } from 'utils/region';

const reportNameMapping = {
  uxo: 'UXOs',
  militaryActivity: 'Military Activity',
  naturalDisaster: 'Natural Disaster',
  structureHazard: 'Structure Hazard',
  terroristActivity: 'Terrorist Activity',
  criminalActivity: 'Criminal Activity',
  warCrime: 'War Crimes',
  wildlife: 'Wildlife Hazard',
  resourceScarcity: 'Resource Scarcity',
  nasa: 'NASA Reports',
};

const BasicReport = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [selectedInvestigation, setSelectedInvestigation] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfDay(subMonths(new Date(), 12)),
    endDate: endOfDay(new Date()),
  });
  const [selectedReportTypes, setSelectedReportTypes] = useState([]); 

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [investigations, setInvestigations] = useState([]);
  const [resetMap, setResetMap] = useState(false); 

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      let q = query(collection(firestore, 'Reports'));
      const querySnapshot = await getDocs(q);
  
      const reportsList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const hazardType = hazardTypeMapping[data.hazardType] || data.hazardType;
        const bombType = bombTypeMapping[data.bombType] || data.bombType;
  
        reportsList.push({
          documentId: doc.id,
          reportId: data.reportId,
          reportType: reportNameMapping[data.reportType] || data.reportType,
          reportingApp: data.reportingApp,
          latitude: data.latitude,
          longitude: data.longitude,
          timeReported: data.timeReported,
          city: data.city,
          state: data.state,
          country: data.country,
          investigation: data.investigation,
          hazardType,  
          bombType,   
          priority: data.priority,
          verifiedStatus: data.verifiedStatus,
        });
      });
  
      setReports(reportsList);
      setFilteredReports(reportsList); 
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setIsLoading(false); 
    }
  }, []);
  

useEffect(() => {
  setResetMap(false);
  if (location.state) {
    const { country, reportType, region, state, city, topic, investigation, dateRange } = location.state;

    setSelectedRegion(region || []); 
    setSelectedCountry(country || []);
    setSelectedState(state || []); 
    setSelectedCity(city || []); 
    setSelectedCategory(reportType || []); 
    setSelectedTopic(topic || []); 
    setSelectedInvestigation(investigation || []); 

    setSelectedDateRange({
      startDate: dateRange?.startDate || startOfDay(subMonths(new Date(), 12)), 
      endDate: dateRange?.endDate || endOfDay(new Date()), 
    });
    setResetMap(true);
  } else {

    setSelectedRegion([]);
    setSelectedCountry([]);
    setSelectedState([]);
    setSelectedCity([]);
    setSelectedCategory([]);
    setSelectedTopic([]);
    setSelectedInvestigation([]);
    setSelectedDateRange({
      startDate: startOfDay(subMonths(new Date(), 12)),
      endDate: endOfDay(new Date()),
    });
    setResetMap(true);
  }

  fetchData(); 
}, [location.state, fetchData]);

  const applyFilters = () => {
    let filtered = [...reports];

    if (selectedRegion.length > 0) {
      filtered = filtered.filter((report) =>
        selectedRegion.some((region) =>
          geographicRegions[region].includes(report.country)
        )
      );
    }

    const availableCountries = Array.from(new Set(filtered.map((report) => report.country)));
    setCountries(availableCountries.map((country) => ({ value: country, label: country })));

    if (selectedCountry.length > 0) {
      filtered = filtered.filter((report) => selectedCountry.includes(report.country));
    }

    const availableStates = Array.from(new Set(filtered.map((report) => report.state)));
    setStates(availableStates.map((state) => ({ value: state, label: state })));

    if (selectedState.length > 0) {
      filtered = filtered.filter((report) => selectedState.includes(report.state));
    }

    const availableCities = Array.from(new Set(filtered.map((report) => report.city)));
    setCities(availableCities.map((city) => ({ value: city, label: city })));

    if (selectedCity.length > 0) {
      filtered = filtered.filter((report) => selectedCity.includes(report.city));
    }

    const availableCategories = Array.from(new Set(filtered.map((report) => report.reportType)));
    setCategories(availableCategories.map((category) => ({ value: category, label: category })));

    if (selectedCategory.length > 0) {
      filtered = filtered.filter((report) => selectedCategory.includes(report.reportType));
    }

    const availableTopics = Array.from(
      new Set(
        filtered.map((report) => report.hazardType || report.bombType) 
      )
    );

    setTopics(availableTopics.map((topic) => ({ value: topic, label: topic })));

    if (selectedTopic.length > 0) {
      filtered = filtered.filter(
        (report) =>
          selectedTopic.includes(report.hazardType) || selectedTopic.includes(report.bombType)
      );
    }
    const availableInvestigations = Array.from(new Set(filtered.map((report) => report.investigation)));
    setInvestigations(availableInvestigations.map((investigation) => ({ value: investigation, label: investigation })));

    if (selectedInvestigation.length > 0) {
      filtered = filtered.filter((report) => selectedInvestigation.includes(report.investigation));
    }

    filtered = filtered.filter((report) => {
      if (report.timeReported && report.timeReported.seconds) {
        const reportDate = new Date(report.timeReported.seconds * 1000);
        return reportDate >= selectedDateRange.startDate && reportDate <= selectedDateRange.endDate;
      }
      return false;
    });

    if (selectedReportTypes.length > 0) {
      filtered = filtered.filter((report) => selectedReportTypes.includes(report.reportType));
    }
  
    setFilteredReports(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [reports, selectedRegion, selectedCountry, selectedState, selectedCity, selectedCategory, selectedTopic, selectedInvestigation, selectedDateRange, selectedReportTypes]);

  const handleFilterChange = (filterType, selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    switch (filterType) {
      case 'region':
        setSelectedRegion(selectedValues);
        break;
      case 'country':
        setSelectedCountry(selectedValues);
        break;
      case 'state':
        setSelectedState(selectedValues);
        break;
      case 'city':
        setSelectedCity(selectedValues);
        break;
      case 'category':
        setSelectedCategory(selectedValues);
        break;
      case 'topic':
        setSelectedTopic(selectedValues);
        break;
      case 'investigation':
        setSelectedInvestigation(selectedValues);
        break;
      default:
        break;
    }
  };

  const handleDateRangeSelect = (startDate, endDate) => {
    setSelectedDateRange({ startDate, endDate });
  };

  const handleCheckboxChange = (updatedSelectedTypes) => {
    setSelectedReportTypes(updatedSelectedTypes);
  };

  //  CSV export
  const csvData = filteredReports.map(report => ({
      archived: report.archived,
      city: report.city,
      contactMethod: report.contactMethod,
      contaminant: report.contaminant,
      country: report.country,
      closedForComments: report.closedForComments,
      closedForUpdates: report.closedForUpdates,
      description: report.description,
      equipment: report.equipment,
      latitude: report.latitude,
      longitude: report.longitude,
      originalReportUserId: report.originalReportUserId,
      originalSocialMediaLink: report.originalSocialMediaLink,
      priority: report.priority,
      protectiveMeasures: report.protectiveMeasures,
      reportId: report.reportId,
      reportingApp: report.reportingApp,
      reportName: report.reportName,
      reportType: report.reportType,
      resourcesThreatened: report.resourcesThreatened,
      initalSafetyStatus: report.initalSafetyStatus,
      verifiedSafetyStatus: report.verifiedSafetyStatus,
      size: report.size,
      state: report.state,
      timeObserved: report.timeObserved && report.timeObserved.seconds ? new Date(report.timeObserved.seconds * 1000).toLocaleString() : '',
      timeReported: report.timeReported && report.timeReported.seconds ? new Date(report.timeReported.seconds * 1000).toLocaleString() : '',
      hazardType: report.hazardType,
      bombType: report.bombType,
      unitCountry: report.unitCountry,
      verificationStatus: report.verificationStatus,
      verificationTimestamp: report.verificationTimestamp && report.verificationTimestamp.seconds ? new Date(report.verificationTimestamp.seconds * 1000).toLocaleString() : '',
      verificationUpVotes: report.verificationUpVotes,
      verificationDownVotes: report.verificationDownVotes,
      verifiedByUserId: report.verifiedByUserId,
      withChemicals: report.withChemicals,

      hasPhotoAttached: report.photoUrl && report.photoUrl.length > 0 ? 'true' : 'false',
      hasVideoAttached: report.videoUrl && report.videoUrl.length > 0 ? 'true' : 'false',
      hasDocumentAttached: report.docPdfUrl && report.docPdfUrl.length > 0 ? 'true' : 'false',

  }));

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner />
        <p>Loading data...</p>
      </div>
    );
  }

  return (

    <div >
      <Card style={{  width: '93%',marginTop: '75px', marginLeft: '40px', marginRight: '40px' }}>
        <CardBody>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label>Geographic Region</Label>
                <CustomizableSelect
                  options={Object.keys(geographicRegions).map((region) => ({
                    value: region,
                    label: region,
                  }))}
                  isMulti
                  value={selectedRegion.map((region) => ({ value: region, label: region }))}
                  onChange={(selectedOptions) => handleFilterChange('region', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Country</Label>
                <CustomizableSelect
                  options={countries}
                  isMulti
                  value={selectedCountry.map((country) => ({ value: country, label: country }))}
                  onChange={(selectedOptions) => handleFilterChange('country', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>State/Province</Label>
                <CustomizableSelect
                  options={states}
                  isMulti
                  value={selectedState.map((state) => ({ value: state, label: state }))}
                  onChange={(selectedOptions) => handleFilterChange('state', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>City</Label>
                <CustomizableSelect
                  options={cities}
                  isMulti
                  value={selectedCity.map((city) => ({ value: city, label: city }))}
                  onChange={(selectedOptions) => handleFilterChange('city', selectedOptions)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label>Investigation</Label>
                <CustomizableSelect
                  options={investigations}
                  isMulti
                  value={selectedInvestigation.map((investigation) => ({ value: investigation, label: investigation }))}
                  onChange={(selectedOptions) => handleFilterChange('investigation', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Category</Label>
                <CustomizableSelect
                  options={categories}
                  isMulti
                  value={selectedCategory.map((category) => ({ value: category, label: category }))}
                  onChange={(selectedOptions) => handleFilterChange('category', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Topic</Label>
                <CustomizableSelect
                  options={topics}
                  isMulti
                  value={selectedTopic.map((topic) => ({ value: topic, label: topic }))}
                  onChange={(selectedOptions) => handleFilterChange('topic', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Date Range</Label>
                <DateRangePickerComponent
                  selectedDateRange={selectedDateRange}
                  onDateRangeSelect={handleDateRangeSelect}
                  defaultStartDate={startOfDay(subMonths(new Date(), 12))}
                  defaultEndDate={endOfDay(new Date())}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div style={{  width: '93%', marginTop: '40px', marginLeft: '40px', marginRight: '40px' }} >
      <ExploreMap
        resetMap={resetMap}
        reports={filteredReports}
        parentSelectedTypes={selectedReportTypes}
        onCheckboxChange={handleCheckboxChange}
        //selectedCountries={selectedCountry}
      />

      </div>
      <div>
        <Card style={{ width: '93%', marginLeft: '40px', marginRight: '45px' }}>
          <Row>
            <Col style={{ marginTop: '50px', marginLeft: '40px', justifyContent: 'flex-start' }}>
              <CardTitle tag="h5">Reports</CardTitle>
              <Label>Backend Development</Label>
            </Col>
            <Col>
              <div style={{ marginTop: '60px', display: 'flex', justifyContent: 'flex-end' }}>
                <span style={{ marginRight: '12px', fontSize: '12px' }}>DOWNLOAD</span>
                <DownloadButtonBasicReport data={csvData} fileName="Reports" />
              </div>
            </Col>
          </Row>
          <ReportsTable reports={filteredReports} />
        </Card>
      </div>

    </div>
  );
};

export default BasicReport;
