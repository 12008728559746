import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Input, Label, Row, Col, CardSubtitle } from 'reactstrap';
import { VectorMap as JVectorMap } from 'react-jvectormap';


const reportTypes = [
  { type: 'uxo', color: '#FF2C2C', label: 'UXOs' },
  { type: 'militaryActivity', color: '#04c4bc', label: 'Military Activity' },
  { type: 'wildlife', color: '#a2845e', label: 'Wildlife' },
  { type: 'warCrime', color: '#5454d4', label: 'War Crimes' },
  { type: 'criminalActivity', color: '#fc9404', label: 'Criminal Activity' },
  { type: 'resourceScarcity', color: '#40c878', label: 'Resource Scarcity' },
  { type: 'structureHazard', color: '#ffcc00', label: 'Structure Hazard' },
  { type: 'naturalDisaster', color: '#047cfc', label: 'Natural Disaster' },
  { type: 'terroristActivity', color: '#000000', label: 'Terrorist Activity' },
  { type: 'nasa', color: '#32ace5', label: 'NASA Reports' },
];

const reportNameMapping = {
  uxo: 'UXO',
  militaryActivity: 'Military Activity',
  naturalDisaster: 'Natural Disaster',
  structureHazard: 'Structure Hazard',
  terroristActivity: 'Terrorist Activity',
  criminalActivity: 'Criminal Activity',
  warCrime: 'War Crimes',
  wildlife: 'Wildlife Hazard',
  resourceScarcity: 'Resource Scarcity',
  nasa: 'NASA Reports',
};

const ExploreMapMain = ({ reports }) => {
  const [selectedReportTypes, setSelectedReportTypes] = useState(reportTypes.map((rt) => rt.type));

  useEffect(() => {
  }, [reports]);

  const handleCheckboxChange = (type) => {
    const updatedTypes = selectedReportTypes.includes(type)
      ? selectedReportTypes.filter((t) => t !== type)
      : [...selectedReportTypes, type];
    setSelectedReportTypes(updatedTypes);
  };

  const handleSelectAll = () => {
    setSelectedReportTypes(
      selectedReportTypes.length === reportTypes.length ? [] : reportTypes.map((rt) => rt.type)
    );
  };

  const handleMarkerClick = (e, index) => {
    const markerId = markers[index].id;
    localStorage.setItem('referrer', window.location.pathname); 
    window.open(`/admin/report/view/${markerId}`, '_blank');
  };
  
  const markers = reports
    .filter((report) => selectedReportTypes.includes(report.reportType) && report.latitude && report.longitude)
    .map((report) => ({
      latLng: [report.latitude, report.longitude],
      name: `Report: ${reportNameMapping[report.reportType] || report.reportType}`,
      style: { fill: reportTypes.find((rt) => rt.type === report.reportType)?.color || '#000' },
      id: report.reportId,  
    }));

  const reportTypeItemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const circleStyle = (color) => ({
    backgroundColor: color,
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    marginRight: '10px',
  });

  const checkboxStyle = {
    marginLeft: '200px',
    width: '16px',
    height: '16px',
    appearance: 'checkbox',
    outline: 'none',
  };

  return (
    <Card className="map" style={{ overflow: 'visible' }}>
      <CardBody>
        <Row>
          <Col md="4" style={{ overflowY: 'auto', maxHeight: '500px', marginBottom: '50px' }}>
            <CardTitle tag="h5">Explore the Map</CardTitle>
            <CardSubtitle style={{ marginBottom: '20px', display: 'block' }}>Topic Filter</CardSubtitle>
            <div style={reportTypeItemStyle}>
              <Label style={{ marginLeft: '33px', color: 'black' }}>Select All</Label>
              <Input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedReportTypes.length === reportTypes.length}
                style={checkboxStyle}
              />
            </div>
            {reportTypes.map((reportType) => (
              <div key={reportType.type} style={reportTypeItemStyle}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="report-type-color" style={circleStyle(reportType.color)}></div>
                  <Label style={{ color: 'black' }}>{reportType.label}</Label>
                </div>
                <Input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(reportType.type)}
                  checked={selectedReportTypes.includes(reportType.type)}
                  style={checkboxStyle}
                />
              </div>
            ))}
          </Col>
          <Col md="8" style={{ overflow: 'hidden' }}>
            <div style={{ height: '90%', width: '100%' }}>
              <JVectorMap
                map="world_mill"
                backgroundColor="#E0E0E0"
                containerStyle={{
                  width: '100%',
                  height: '100%',
                }}
                markers={selectedReportTypes.length > 0 ? markers : []}
                markerStyle={{
                  initial: {
                    fill: '#FF0000',
                    stroke: '#000',
                    "stroke-width": 0.3,
                    r: 6,
                  },
                  hover: {
                    stroke: '#000',
                    "stroke-width": 2,
                    cursor: 'pointer',
                  },
                }}
                onMarkerClick={handleMarkerClick} 
              />
            </div>
          </Col>
        </Row>
      </CardBody>
      <p style={{ fontSize: '10px', marginLeft:'20px' }} >* If the checkboxes are grayed out, there are no reports.</p>
    </Card>
  );
};

export default ExploreMapMain;
