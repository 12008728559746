import React, { useEffect, useState, useCallback, useRef } from 'react';
import { collection, query, getDocs, where, Timestamp } from 'firebase/firestore';
import { Row, Col, Spinner, Button, Modal, ModalBody } from 'reactstrap';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { firestore } from '../../firebase';
import CustomizableSelect from '../components/CustomizableSelect';
import DateRangePickerComponent from '../components/DateRangePickerComponent';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import VideoPlayer from '../components/VideoPlayer';
import PhotoViewer from '../components/PhotoViewer';

const reportNameMapping = {
  uxo: 'UXOs',
  militaryActivity: 'Military Activity',
  naturalDisaster: 'Natural Disaster',
  structureHazard: 'Structure Hazard',
  terroristActivity: 'Terrorist Activity',
  criminalActivity: 'Criminal Activity',
  warCrime: 'War Crimes',
  wildlife: 'Wildlife Hazard',
  resourceScarcity: 'Resource Scarcity',
  nasa: 'NASA Reports',
};

const safetyStatusMapping = {
  '0': 'Safety conditions are currently unknown',
  '1': 'Area is safe',
  '2': 'Area is not safe',
};


const getIconUrl = (reportType) => {
  let iconUrl;
  try {
    iconUrl = require(`assets/img/typeofendpoints/${reportType}.png`);
  } catch (error) {
    iconUrl = "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless_hdpi.png";
  }
  return iconUrl;
};

const formatTimeReported = (timeReported) => {
  if (!timeReported?.seconds) return 'Unknown Time';
  const date = new Date(timeReported.seconds * 1000);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) +
    ' ' + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
};

const MapPage = () => {
  const mapRef = useRef(null);
  const [selectedReportOption, setSelectedReportOption] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [defaultStartDate] = useState(startOfDay(subMonths(new Date(), 12)));
  const [defaultEndDate] = useState(endOfDay(new Date()));
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [latestSafetyStatus, setLatestSafetyStatus] = useState(null);
  const [latestTimeReported, setLatestTimeReported] = useState(null);
  const [latestPhotoUrl, setLatestPhotoUrl] = useState([]);
  const [latestVideoUrl, setLatestVideoUrl] = useState([]);

  const fetchSubcollection = async (reportId, subcollectionName) => {
    try {
      const subcollectionRef = collection(firestore, `Reports/${reportId}/${subcollectionName}`);
      const subcollectionSnapshot = await getDocs(subcollectionRef);
      return !subcollectionSnapshot.empty ? subcollectionSnapshot : null;
    } catch (error) {
      console.log(`Subcollection ${subcollectionName} does not exist for report ${reportId}.`);
      return null;
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchReportData = async () => {
      setIsLoading(true);
      try {
        let queryConstraints = [
          where('timeReported', '>=', Timestamp.fromDate(selectedDateRange.startDate)),
          where('timeReported', '<=', Timestamp.fromDate(selectedDateRange.endDate)),
        ];
  
        if (selectedReportOption.length > 0) {
          const reportTypes = selectedReportOption.map(option => option.value);
          queryConstraints.push(where('reportType', 'in', reportTypes));
        }
  
        const q = query(collection(firestore, 'Reports'), ...queryConstraints);
        const querySnapshot = await getDocs(q);
        const reports = [];
  
        querySnapshot.forEach((doc) => {
          const report = doc.data();
          const latitude = parseFloat(report.latitude);
          const longitude = parseFloat(report.longitude);
  
          if (!isNaN(latitude) && !isNaN(longitude)) {
            reports.push({
              ...report,
              id: doc.id,
              latLng: { lat: latitude, lng: longitude },
            });
          }
        });
  
        if (isMounted) {
          setMapData(reports);
        }
      } catch (error) {
        console.error('Error fetching report data:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false); 
        }
      }
    };
  
    fetchReportData();
  
    return () => {
      isMounted = false;
    };
  }, [selectedReportOption, selectedDateRange]);
  

const fetchCompleteReportData = async (report) => {
  try {
    const { id } = report;
    const [updateSnapshot, hazardUpdateSnapshot, commentSnapshot] = await Promise.all([
      fetchSubcollection(id, 'Update'),
      fetchSubcollection(id, 'HazardUpdate'),
      fetchSubcollection(id, 'Comment'),
    ]);

    let photoUrl = report.photoUrl || [];
    let videoUrl = report.videoUrl || [];
    let safetyStatus = report.safetyStatus || '0';
    let timeReported = report.timeReported;

    if (updateSnapshot) {
      updateSnapshot.forEach(subDoc => {
        const updateData = subDoc.data();
        safetyStatus = updateData.safetyStatus || safetyStatus;
        photoUrl = [...photoUrl, ...(updateData.photoUrl || [])];
        videoUrl = [...videoUrl, ...(updateData.videoUrl || [])];
        if (updateData.timeReported?.seconds > timeReported?.seconds) {
          timeReported = updateData.timeReported;
        }
      });
    }
    if (hazardUpdateSnapshot) {
      hazardUpdateSnapshot.forEach(subDoc => {
        const hazardData = subDoc.data();
        safetyStatus = hazardData.safetyStatus || safetyStatus;
        if (hazardData.timeReported?.seconds > timeReported?.seconds) {
          timeReported = hazardData.timeReported;
        }
      });
    }
    if (commentSnapshot) {
      commentSnapshot.forEach(subDoc => {
        const commentData = subDoc.data();
        photoUrl = [...photoUrl, commentData.supportingPhotoUrl].filter(Boolean);
        videoUrl = [...videoUrl, commentData.supportingVideoUrl].filter(Boolean);
      });
    }
    const mappedSafetyStatus = safetyStatus === '0' ? 'Safety conditions are currently unknown'
      : safetyStatus === '1' ? 'Area is safe'
      : 'Area is not safe';
    setLatestSafetyStatus(mappedSafetyStatus);
    setLatestPhotoUrl(photoUrl);
    setLatestVideoUrl(videoUrl);
    setLatestTimeReported(timeReported);
    setSelectedMarker(report);
    setInfoWindowPosition({ lat: report.latLng.lat, lng: report.latLng.lng });

  } catch (error) {
    console.error('Error fetching complete report data:', error);
  }
};

  
  const initializeMap = useCallback(() => {
    if (mapRef.current && mapData.length > 0) {
      const google = window.google;
      const mapOptions = {
        zoom: 3,
        center: mapData[0]?.latLng || { lat: 20, lng: 0 },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        fullscreenControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DEFAULT,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      };
  
      const map = new google.maps.Map(mapRef.current, mapOptions);
  
      const markers = mapData.map((report) => {
        const marker = new google.maps.Marker({
          position: report.latLng,
          title: report.city || 'Unknown Location',
          icon: {
            url: getIconUrl(report.reportType),
            scaledSize: new google.maps.Size(30, 30),
          },
        });
  
        marker.addListener('click', async () => {
          await fetchCompleteReportData(report);
        });
  
        return marker;
      });
  
      new MarkerClusterer({ markers, map });
    }
  }, [mapData]);
  
  useEffect(() => {
    if (!isLoading && mapData.length > 0) {
      initializeMap();
    }
  }, [isLoading, mapData, initializeMap]);

  const handleMediaClick = () => {
    setShowMediaModal(true);
  };

  const closeMediaModal = () => {
    setShowMediaModal(false);
  };

  const handleReportTypeChange = (selectedOptions) => {
    setSelectedReportOption(selectedOptions || []);
  };

  const onDateRangeSelect = (startDate, endDate) => {
    setSelectedDateRange({ startDate, endDate });
  };

  const reportOptions = Object.entries(reportNameMapping).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: '60px',
          left: '20px',
          zIndex: 1000,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          width: '95%',
        }}
      >
        <Row form>
          <Col md={6} style={{ marginBottom: '10px', zIndex: 1500 }}>
            <CustomizableSelect
              options={reportOptions}
              placeholder="Select a report"
              onChange={handleReportTypeChange}
              isMulti={true}
              value={selectedReportOption}
            />
          </Col>
          <Col md={6} style={{ zIndex: 1500 }}>
            <DateRangePickerComponent
              selectedDateRange={selectedDateRange}
              onDateRangeSelect={onDateRangeSelect}
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
            />
          </Col>
        </Row>
      </div>

      <div ref={mapRef} style={{ width: '100%', height: '100%' }}>
        {isLoading && (
          <div style={{ textAlign: 'center', paddingTop: '50px' }}>
            <Spinner />
            <p>Loading map data...</p>
          </div>
        )}

        {selectedMarker && infoWindowPosition && (
          <div
            style={{
              position: 'absolute',
              left: `50%`,
              top: `50%`,
              backgroundColor: 'rgba(255, 255, 255, 1)',
              padding: '15px',
              borderRadius: '8px',
              color: 'black',
              maxWidth: '300px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
              zIndex: 1000,
            }}
          >
            <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'space-between', textTransform: 'uppercase', alignItems: 'center' }}>
              <p>{reportNameMapping[selectedMarker.reportType]}</p>
              <Button style={{ marginTop: '1px' }} close onClick={() => setSelectedMarker(null)} />
            </div>
            <p style={{ fontWeight: '600px' }}>
              {latestSafetyStatus || 'Safety Conditions Currently Unknown'}
            </p>
            <p>
              {formatTimeReported(latestTimeReported)}
            </p>
            <Button
              className="btn btn-info"
              href={`/admin/report/view/${selectedMarker.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View More
            </Button>
            <Button className="btn btn-outline-neutral" onClick={handleMediaClick}>
              View Media
            </Button>
          </div>
        )}

        <Modal isOpen={showMediaModal} toggle={closeMediaModal}>
          <ModalBody>
            {latestPhotoUrl && latestPhotoUrl.length > 0 ? (
              <PhotoViewer photoUrls={latestPhotoUrl} />
            ) : (
              latestVideoUrl && latestVideoUrl.length > 0 ? (
                <p style={{ textAlign: 'center', marginTop:'20px' }}> No photo attached to report</p>
              ) : null
            )}
            {latestVideoUrl && latestVideoUrl.length > 0 ? (
              <VideoPlayer videoUrl={latestVideoUrl} />
            ) : (
              latestPhotoUrl && latestPhotoUrl.length > 0 ? (
                <p style={{ textAlign: 'center', marginTop:'20px'}}> No video attached to report</p>
              ) : null
            )}
            {!latestPhotoUrl.length && !latestVideoUrl.length && (
              <p style={{ textAlign: 'center', marginTop:'20px' }}> There are no media attached to the report.</p>
            )}
          </ModalBody>
        </Modal>

      </div>
    </div>
  );
};

export default MapPage;